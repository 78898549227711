.location_details {
    .name_input.MuiTextField-root {
        margin-top: 2rem;
    }
}

.location_note {
    padding-left: 0.9rem;
    width: 88%;

    .notes_container {

        &_textarea {
            border: 1px solid rgba(112, 112, 112, .2);
            border-radius: 8px;
            padding: .5em;
            height: 23em;
            margin-top: 1rem;
            overflow-y: auto;
            font-size: 0.83rem;

            &_data {
                width: 100%;
                max-width: 93%;
                background: #f5f5f5;
                padding: .5em;
                word-wrap: break-word;

                @media (max-width: 770px) {
                    max-width: 100%;
                }

                &_has_content {
                    width: 100%;
                    max-width: 93%;
                    background: #f5f5f5;
                    padding: .5em;
                    margin-bottom: .5em;

                    @media (max-width: 770px) {
                        max-width: 100%;
                    }
                }

                span {
                    word-wrap: break-word;
                }
            }
        }
    }
}

.location_contact {
    .contact_box {
        margin-bottom: 1.5rem;

        .first_contact_input {
            margin-top: 0;
        }
    }
}

.location_crud_form {
    .rates_title {
        margin-top: 3rem;
    }
}

.validation_error {
    font-size: 0.8125rem;
    color: red;
}

