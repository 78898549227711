.MuiDialog-paper[role="dialog"] {
    max-width: 100vw;
    margin: 32px;

    @media only screen and (max-width: 600px) {
        margin: 5px;
    }
}

.MuiDialog-paper[role="presentation"] {
    margin: 32px;

    @media only screen and (max-width: 600px) {
        margin: 5px;
    }
}

#cancel-btn {
    @media only screen and (max-width: 600px) {
        margin-left: -1em;
        font-size: 0.6rem;
    }
}

.cancel_btn {
    text-align: end;
}

.clockIn_questions {
    padding-top: 1.5em;
}

.clockOut_questions {
    padding-bottom: 1.5em;
}