.container {
  padding: 0 5% 0% 5%;
}

@media print {
  header {
    display: none !important;
  }

  .container {
    padding: 0 2% 0 2%;
  }

  .back-button {
    display: none !important;
  }

  .margin-block-header {
    margin-block-start: 0.25 !important;
    margin-block-end: 0.25 !important;
  }
}

thead {
  display: table-header-group !important;
}

//======================== HEADER
.invoice-date-tittle {
  width: 5.625em;
}

.invoice-work-order-tittle {
  width: 5.625em;
  padding-left: 1.2em;
}

.invoice-date {
  width: 5.625em;
}

.invoice-work-order {
  width: 5.625em;
}

.tittle {
  align-self: flex-start;
}

.date {
  margin-left: auto;

  & .flex-row {
    & h5 {
      white-space: nowrap;
    }
  }
}

//======================== PARTS
.parts-page {
  page-break-before: avoid;

  & table {
    width: 100%;
    border-collapse: collapse;

    & thead>tr>td {
      text-align: center;
    }

    & thead>tr>td.txt-left {
      text-align: left;
    }
  }
}

.parts-total-table {
  & table {
    width: 100%;
    border-collapse: collapse;

    & thead>tr>td {
      text-align: center;
    }
  }
}

//=======================
.back-button {
  margin-top: 2% !important;
  margin-left: 1% !important;
  height: 10%;
  width: 4%;
}

.download-button {
  margin-top: 2% !important;
  margin-left: 55% !important;
  height: 10%;
  width: 6%;
}

.progress {
  position: fixed;
  left: 50%;
  top: 35%;
  z-index: 1000;
}

// HELPER CLASSES

.tbody-bottom-border {
  border-bottom: 1px solid;
  border-collapse: collapse;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.ml-10 {
  margin-left: 10%;
}

.ml-20 {
  margin-left: 20%;
}

.ml-80 {
  margin-left: 80%;
}

.width-50 {
  width: 50%;
}

.width-55 {
  width: 55%;
}

.width-15 {
  width: 15%;
}

.width-20 {
  width: 20%;
}

.margin-block {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.margin-block-header {
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
}

.table-cell-border {
  border: 1px solid;
}

.td-border {
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.visit-table-cell {
  padding-left: 2rem;
  padding-right: 2rem;
}

.table-row-border {
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.border-right {
  border-right: 1px solid;
}

.border-left {
  border-left: 1px solid;
}

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.txt-left {
  text-align: left;
}

.margin-l-auto {
  margin-left: auto;
}