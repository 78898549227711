.priority_and_trades {
    form {
        width: 100%;
        min-height: 10em;
        max-height: 600px;
        height: inherit;
    }

    .MuiSelect-select {
        &:focus {
            background-color: transparent;
        }
    }

    &_form {
        &_container {

            @media (min-width: 1900px) {
                margin-right: 2em !important;
            }

            @media (max-width: 768px) {
                margin-bottom: 1em !important;
            }
        }

        &_title {
            padding: 1em;

            p {
                font-size: 1.3em;
            }
        }

        &_add_data {
            &_container {

                &_pick_color {
                    width: 100%;

                    &_text {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 72px !important;
                        height: 36px !important;
                    }

                    &_select {
                        width: 95px !important;
                        height: 56px !important;
                    }
                }

                &_button {
                    margin: .25em 0 0 !important;

                    @media (min-width: 400px) and (max-width: 768px) {
                        margin: 1em 0 !important;
                        width: 21em;
                    }

                    @media (max-width: 400px) {
                        margin: 1em 0 !important;
                        width: 20.5em;
                    }

                    button {
                        width: 100%;
                        height: 40px;

                        @media (max-width: 768px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &_table {
        width: 100%;

        thead {
            tr {

                &:nth-child(1)>th:nth-child(1),
                &:nth-child(1)>th:nth-child(3) {
                    text-align: center;
                }

                &:nth-child(1)>th:nth-child(1) {
                    width: 2em;
                }

                &:nth-child(1)>th:nth-child(2) {
                    width: 100%;
                }
            }
        }

        &_cell {
            width: 72px;
            height: 36px;
            border-radius: 8px;
        }
    }

    &_popover {
        position: absolute;
        z-index: 11;
    }

    &_cover {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }

    &_color_input {
        .MuiInputBase-input {
            width: 98px !important;
            height: 36px !important;
        }
    }

    .MuiSelect-icon {
        visibility: hidden;
    }

    @media (min-width: 1360px) {
        .MuiGrid-grid-lg-3 {
            max-width: 23%;
        }
    }
}

.validation_error {
    font-size: 0.8125rem;
    color: red;
}