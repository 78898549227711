.location {

    hr {
        margin: 1.5em 0;
        border: 1px solid #e7e7e7;
        width: 100%;
    }

    @media (min-width: 1280px) {
        margin-top: 2em;

        &_container {
            box-shadow: 1px 0 0 #c6c6c6;
        }
    }

    &_crud {
        &_form {
            margin-bottom: 2em;

            &_title {
                @media (max-width: 768px) {
                    margin-top: 1em !important;
                }
            }

            &_clr_fields {
                justify-content: flex-end;

                @media (max-width: 748px) {
                    justify-content: flex-start;
                }
            }
        }
    }

    &_table,
    &_crud_form {
        @media screen and (max-width: 748px) {
            margin-top: 2em;
        }

        @media screen and (min-width: 1280px) {
            padding: 0 2em;
        }
    }

    &_details,
    &_note,
    &_contact {
        p {
            margin: .5em 0;
            font-size: 1.13em;
            font-weight: 100;
            color: #3f51b5;
        }
    }

    &_note_textbox,
    &_contact_input_fields {
        margin-top: 3em !important;
    }

    &_contact_input_fields {
        overflow: auto;

        @media (min-width: 1280px) {
            height: 24.8em;
        }

        &_readonly {
            margin-top: 0.5rem;
        }
    }

    &_form_footer {
        margin-top: 2em;

        &_pm_info {
            width: 18em;
        }
    }

    &_rates {
        p {
            margin: .5em 0;
            font-size: 1.13em;
            font-weight: 100;
            color: #3f51b5;
        }
    }

    @media (max-width: 748px) {

        &_note,
        &_contact {
            width: 100%;
            margin-top: 1em;

            &_textbox,
            &_input_fields {
                width: 100%;
                margin-top: 0 !important;
            }
        }
    }

    @media (max-width: 768px) {
        &_contact {
            margin-top: 1em;
        }
    }

    @media (max-width: 720px) {

        &_rates_input:nth-child(odd) {
            padding-right: .5em !important;
        }
    }

    .MuiFormControl-fullWidth {
        margin: .5em 0;

        @media (max-width: 768px) {
            margin: .45em 0;
        }
    }

    .icon {
        margin-top: 0.5rem;
    }
}