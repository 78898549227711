@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url("https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf");
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: "Roboto";
  height: 100%;
  background: #fff;
}

.hide_password_input_field {
  input {
    font-family: "password";
  }
}

.hidden_component {
  &_xl {
    @media (min-width: 1366px) {
      visibility: hidden;
    }
  }

  &_lg {
    @media (min-width: 1280px) and (max-width: 1366px) {
      visibility: hidden;
    }
  }

  &_md {
    @media (min-width: 810px) and (max-width: 1024px) {
      visibility: hidden;
    }
  }
  &_sm {
    @media (min-width: 520px) and (max-width: 780px) {
      visibility: hidden;
    }
  }

  &_xs {
    @media (max-width: 480px) {
      visibility: hidden;
    }
  }
}

.remove_component {
  &_xl {
    @media (min-width: 1366px) {
      display: none !important;
    }
  }

  &_lg {
    @media (min-width: 1280px) and (max-width: 1366px) {
      display: none !important;
    }
  }

  &_md {
    @media (min-width: 760px) and (max-width: 1024px) {
      display: none !important;
    }
  }

  &_xs {
    @media (max-width: 480px) {
      display: none !important;
    }
  }
}

.validation_error {
  font-size: 0.8125rem;
  color: red;
}

#phaseOneKanban {
  width: 400px !important;
  height: 100vh !important;
}

.smart-kanban {
  height: fit-content !important;
  min-height: 200px !important;
  width: 60vw !important;
}

.smart-kanban .smart-kanban-column .smart-kanban-column-content-tasks {
  height: fit-content !important;
  min-height: 100px !important;
}
