.equipment {
    @media (min-width: 1280px) {
        margin-top: 2em;
    }

    .equipment_list {
        padding: 0 2em;
        box-shadow: 1px 0 0 #c6c6c6;
    }

    .equipment_details {
        padding-right: 1em;
        margin-right: 2em;
    }

    .equipment_documents {
        margin-top: 1em;

        label {
            margin-top: 0.5em;
        }
    }

    h5:nth-child(1) {
        margin: 1em 0;
    }

    &_submit {
        padding: 1em 0 0;
    }

    &_customer {
        @media (max-width: 500px) {
            margin: 1em 0 !important;
        }
    }

    &_location {
        @media (max-width: 500px) {
            margin-bottom: 1em !important;
        }
    }

    &_list {
        .MTableToolbar-root-239 {
            margin-bottom: 1em;
        }
    }

    &_details {
        @media (min-width: 1030px) and (max-width: 1280px) {
            margin-right: 3.5rem !important;
        }

        @media (min-width: 1290px) and (max-width: 1370px) {
            margin-right: 3.75rem !important;
        }
    }

    &_notes {
        button {
            margin-top: 0.5em;
            margin-bottom: 1em
        }

        .notes_container {
            &_title {
                margin-left: 1.1em;
                margin-bottom: .5em;
                font-size: 1rem;
                opacity: .5;
            }

            &_textarea {
                border: 1px solid rgba(112, 112, 112, .2);
                border-radius: 8px;
                padding: .5em;
                height: 18em;
                margin-bottom: -5em;
                overflow-y: auto;

                &_data {
                    width: 100%;
                    max-width: 93%;
                    background: #f5f5f5;
                    padding: .5em;
                    word-wrap: break-word;

                    @media (max-width: 770px) {
                        max-width: 100%;
                    }

                    &_has_content {
                        width: 100%;
                        max-width: 93%;
                        background: #f5f5f5;
                        padding: .5em;
                        margin-bottom: .5em;

                        @media (max-width: 770px) {
                            max-width: 100%;
                        }
                    }

                    span {
                        word-wrap: break-word;
                    }
                }
            }

            &_textarea_display {
                border: 1px solid rgba(112, 112, 112, .2);
                border-radius: 8px;
                padding: .5em;
                height: 18em;
                // margin-bottom: -5em;
                overflow-y: auto;

                &_data {
                    width: 100%;
                    max-width: 93%;
                    background: #f5f5f5;
                    padding: .5em;
                    word-wrap: break-word;

                    @media (max-width: 770px) {
                        max-width: 100%;
                    }

                    &_has_content {
                        width: 100%;
                        max-width: 93%;
                        background: #f5f5f5;
                        padding: .5em;
                        margin-bottom: .5em;

                        @media (max-width: 770px) {
                            max-width: 100%;
                        }
                    }

                    span {
                        word-wrap: break-word;
                    }
                }
            }
        }

        @media (max-width: 770px) {
            margin-top: 2rem !important;
        }
    }

    &_documents {
        @media (max-width: 770px) {
            margin-top: 5rem !important;
        }

        @media (min-width: 1030px) and (max-width: 1280px) {
            margin-top: 3.5em !important;
        }

        @media (min-width: 1290px) and (max-width: 1370px) {
            margin-top: 4em !important;
        }
    }

    &_add_customer {

        .MuiTableCell-body {
            cursor: unset;
        }

        form {
            padding: 0 2em;

            .divider {
                padding-bottom: 2em;
                margin-bottom: 2em;
                border-bottom: 1px solid rgba(112, 112, 112, .2);
            }

            .sections {
                margin-bottom: 1em;
            }

            .title {
                font-size: 1rem;
                font-weight: 700;
            }

            .label {
                font-size: 1.13em;
                font-weight: 100;
            }

            .document_files {
                height: 100%;
                background: #f9f9f9;
                overflow-y: auto;
                padding: 0 .5em;

                .no_content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }
            }
        }
    }

    .MuiFormControl-fullWidth {
        margin: .5em 0;
    }
}