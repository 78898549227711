.dispatch-documents_container {

    .MuiBox-root {
        overflow: auto;
    }

    .MuiToolbar-root {
        display: none;
    }

    .MuiPaper-root {
        background-color: transparent;
        box-shadow: none;
    }

    .MuiButton-root {
        margin-bottom: 1rem;
    }
}