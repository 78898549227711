.login__page {
    input {
        opacity: .7;
    }

    &__input__container {
        margin-bottom: 2em;
    }

    .MuiPaper {
        &-root {
            img {
                border-radius: 100px;
                margin-bottom: 3em;
            }
        }

        &-rounded {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            min-height: 300px;
        }
    }

    .MuiTextField {
        &-root {
            width: 100%;
        }
    }
}