.dispatch-crud-form.board {

    padding: 0.5rem;

    .dispatch-details-title {
        .MuiTabs-root {
            margin-bottom: 1rem;
        }

        .MuiButtonBase-root {
            min-width: none;

            &.details {
                width: 44%;
            }

            &.src {
                width: 56%;
            }
        }
    }

}