.customer {

    hr {
        margin: 1.5em 0;
        border: 1px solid #e7e7e7;
        width: 100%;
    }

    @media (min-width: 1280px) {
        margin-top: 2em;

        &_container {
            box-shadow: 1px 0 0 #c6c6c6;
        }
    }

    &_crud {
        &_form {
            margin-bottom: 2em;

            &_title {
                @media (max-width: 480px) {
                    padding: 0 1em;
                }
            }

            &_clr_fields {
                justify-content: flex-end;

                @media (max-width: 768px) {
                    justify-content: flex-start;
                }
            }
        }
    }

    &_table,
    &_crud_form {
        @media screen and (max-width: 768px) {
            margin-top: 2em;
        }

        @media screen and (min-width: 1280px) {
            padding: 0 2em;
        }
    }

    &_details,
    &_contacts,
    &_rates {
        padding: 0 1em;

        p {
            margin: .5em 0;
            font-size: 1.13em;
            font-weight: 100;
            color: #3f51b5;
        }
    }

    &_rates_grid {
        margin-top: 1em;
    }

    &_contacts_phone:nth-child(odd) {
        padding-right: 1em;
    }

    @media (max-width: 720px) {

        &_contacts {
            width: 100%;
            margin-top: 1em;
        }

        &_rates_input:nth-child(odd) {
            padding-right: .5em !important;
        }
    }

    .MuiFormControl-fullWidth {
        margin: .5em 0;
    }
}

.validation_error {
    font-size: 0.8125rem;
    color: red;
}