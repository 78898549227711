header {
    height: 64px;

    .MuiToolbar-regular {
        min-height: 64px;
    }

    .MuiListItem {
        &-root {
            width: auto;
        }
    }

    .header_navlinks {
        @media (max-width: 780px) {
            display: none;
        }
    }
}