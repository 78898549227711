.clock-io {
    padding-left: 1rem;

    //ampersand isn't used as you can't specify the selector &_desc together with p in front, and it's needed for specificity 
    //in order to not get overridden
    p.clock-io_desc {
        margin: 2rem 0;
    }

    .MuiFormGroup-root {
        flex-direction: row;
    }

    .clock-io_question {
        margin-bottom: 2rem;

        &-desc {
            color: #3f51b5;
            font-size: 1.2rem;
            font-weight: 400;
        }
    }

    &_submit {
        width: 10rem;
    }

    .validation_errors {
        display: flex;
        flex-flow: column;
        margin-bottom: 1.5rem;

        span {
            font-size: 1rem;
            color: red;
        }
    }
}