.user_crud {
    &_paper {
        p {
            &:nth-child(1) {
                font-size: 18px;
                padding: .75em;
            }
        }

        button {
            margin-bottom: 2em;
        }
    }

    &_checkbox_container {
        height: 3.55em;
        margin-bottom: 1em;
    }

    &_form_fields {
        div {
            margin-bottom: .5em;
        }
    }
}