.dispatch-crud-form {
  .btn-edit-dispatch {
    height: 2rem;
  }

  .MuiTabs-flexContainer {
    .MuiButtonBase-root {
      font-size: 0.8rem;
    }
  }

  .customer-location.disabled {
    .MuiAutocomplete-endAdornment {
      display: none;
    }

    fieldset {
      border: none;
    }

    input {
      color: black;
    }
  }

  .form-disabled {
    opacity: 0.6;
  }

  .btn-add-dispatch {
    height: 100%;

    button {
      height: 100%;
      max-height: 5.63rem;
    }
  }

  .line {
    height: 1px;
    background-color: #ccc;
    border: none;
    margin: 1rem 0.5rem 1.5rem 0;
  }

  .dispatch-details {
    .MuiTypography-h3 {
      margin-bottom: 1rem;
    }

    .MuiGrid-grid-xs-6,
    .MuiGrid-grid-xs-12 {
      margin-bottom: 0.6rem;
    }
  }

  .tabs-container {
    display: block;
    position: relative;

    &_hidden {
      display: none;
    }
  }

  .form-tabs {
    font-size: 0.65rem;
    margin-bottom: 1rem;

    .MuiTabs-flexContainer {
      border-bottom: solid rgba(black, 0.12) 2px;
      margin-right: 0.5rem;
    }

    .MuiTab-root {
      min-width: 2.5rem;
      width: 6.06rem;
      border-bottom: solid transparent 3px;
    }

    .MuiTabs-indicator {
      // width: 80px;
      display: none;
    }

    .Mui-selected {
      border-bottom: solid #3f51b5 3px;
    }

    .MuiTabs-flexContainer {
      overflow-x: auto;
    }

    &.disabled {
      .Mui-selected {
        border-bottom: transparent;
      }
    }
  }

  .form-tab-content {
    width: 100%;
    padding-right: 0.5rem;
    margin-bottom: 1rem;

    .MuiBox-root {
      height: 40rem;
      background-color: rgb(245, 246, 251);
    }

    &.short {
      .MuiBox-root {
        height: 20rem;
      }
    }
  }

  .fw-select {
    width: 100%;
  }

  .update-dispatch {
    padding-right: 0.5rem;
    display: none;

    &_visible {
      display: block;
    }
  }

  .date-created {
    color: rgb(126, 125, 125);
    margin: 0.8rem 0;
    display: block;
  }

  .src {
    margin-top: 1rem;

    .MuiBox-root {
      height: 10rem;
      background-color: rgba(red, 0.1);
    }
  }
}

.MuiDialog-paper[role="dialog"] {
  max-width: 100vw;
  margin: 32px;

  @media only screen and (max-width: 600px) {
    margin: 5px;
  }
}
