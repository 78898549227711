.dispatch-parts_container {

    .MuiBox-root {
        overflow: auto;
    }

    .MuiToolbar-root {
        display: none;
    }

    .MuiPaper-root {
        background-color: transparent;
        box-shadow: none;
    }

}

.parts-modal {
    .MuiPaper-root>div:nth-child(2) {
        height: 40vh;
        margin-bottom: 1rem;

        .MuiSvgIcon-root {
            fill: rgb(63, 81, 181);
        }
    }
}