.my_account {
    .MuiContainer {
        @media (max-width: 400px) {
            &-maxWidthLg {
                padding: 0;
            }
        }
    }

    .MuiTextField {
        &-root {
            width: 100%;
        }
    }

    .MuiFormControl {
        &-root {
            &:nth-child(5)>div:nth-child(2) {
                width: 170px;
            }

            @media (max-width: 400px) {
                &:nth-child(4)>div:nth-child(2) {
                    width: 171px;
                }

                &:nth-child(5)>div:nth-child(2) {
                    width: 202px;
                }
            }
        }
    }

    .MuiInput-underline {

        &::before,
        &:hover:not(.Mui-disabled)::before {
            border-bottom: none;
        }

        &::after {
            visibility: hidden;
        }
    }

    form {
        width: 90%;
    }

    &_paper {
        padding: 1em;
    }

    &_page_title {
        height: 85px;
        margin-bottom: 1em;

        p {
            &:nth-child(1) {
                font-size: 24px;
            }
        }

        @media (max-width: 400px) {
            margin-bottom: 0;
        }
    }

    &_form {
        padding: 0 1em;
        margin-bottom: 2em;

        @media (max-width: 400px) {
            padding: 0;
            margin-bottom: 1em !important;
        }

        p:nth-child(1) {
            font-size: 21px;
            margin-left: calc(1em - 5px);
            font-weight: 700;
        }

        &_fields {
            .MuiFormControl {
                &-root {
                    margin: 1em 0;
                }
            }

            &_hidden {
                visibility: hidden;
            }
        }

        &_caption {
            font-size: 12px;
            color: #374761;
            margin-top: 1em;
        }

        button[type="submit"] {
            margin: 1em 0;
        }
    }

    .my_account_form:nth-child(1)>div:nth-child(1)>div:nth-child(2)>form:nth-child(1)>div:nth-child(1)>div:nth-child(4)>div:nth-child(2) {
        @media (max-width: 400px) {
            width: 171px;
        }
    }
}